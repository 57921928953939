@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Global CSS */
::selection {
  color: red;
  background-color: transparent;
}

::-moz-selection { /* For Firefox */
  color: red;
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
}

.head-logo{
  font-family: 'Bree Serif', cursive;
}

/* Styles for the sticky content with matching background */
.sticky-home-content {
  position: sticky;
  top: 0;
  z-index: -1;
}

a:hover {
    color: rgb(220 38 38);
}

/* Add these to a global CSS file or within a <style> tag in your component */
.link-underlined::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.link-underlined:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.linkedin-underline::after {
  background: #0077B5; /* LinkedIn color */
}

.twitter-underline::after {
  background: #1DA1F2; /* Twitter color */
}

.facebook-underline::after {
  background: #1877F2; /* Facebook color */
}

.instagram-underline::after {
  background: #E4405F; /* Instagram color */
}

.github-underline::after {
  background: #000000; /* GitHub color */
}





